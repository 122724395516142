import $ from "jquery";
import "readmore-js";

window.onload = function () {
  $(".about-member").readmore({
    collapsedHeight: 100,
    moreLink: '<a href="#">Více</a>',
    lessLink: '<a href="#">Zavřít</a>',
  });
};
