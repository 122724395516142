import { createApp } from "vue";

export function mountComponent(selector, appConfig, componentExtraUse) {
  document.querySelectorAll(selector).forEach((element) => {
    const app = createApp(appConfig);

    if (componentExtraUse && componentExtraUse.length > 0) {
      for (let i = 0; i < componentExtraUse.length; i++) {
        app.use(componentExtraUse[i]);
      }
    }
    app.mount(element);
  });
}

export const axiosConfig = {
  headers: {
    "X-CSRFToken": getCSRFToken(),
  },
};

export function getCSRFToken() {
  // get csrf cookie
  return getCookie("csrftoken");
}

export function getCookie(name) {
  // get cookie by name

  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const buildDetailUrl = (base, params) => {
  for (const param in params) {
    base = base.replace(param, params[param]);
  }
  return base;
};
