import { mountComponent } from "src/utils";
import FundraisingDetail from "src/components/FundrasingDetail.vue";

const appConfig = {
  components: { FundraisingDetail },
  data() {
    return {
      detailOpen: false,
      selectedActivity: null,
    };
  },
  methods: {
    openActivity(activityId) {
      this.selectedActivity = activityId;
      this.detailOpen = true;
    },
  },
};

mountComponent(".vueFundraising", appConfig);
