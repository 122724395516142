import { mountComponent } from "src/utils";
import ProjectDetail from "src/components/ProjectDetail.vue";

const appConfig = {
  components: { ProjectDetail },
  data() {
    return {
      detailOpen: false,
      selectedProject: null,
    };
  },
  methods: {
    openProject(projectId) {
      this.selectedProject = projectId;
      this.detailOpen = true;
    },
  },
};

mountComponent(".vueProjects", appConfig);
