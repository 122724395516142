<script setup>
  import ModalWindow from "src/components/UI/ModalWindow.vue";
  import { onMounted, ref } from "vue";
  import axios from "axios";

  const paymentsRef = ref();
  const emit = defineEmits(["closeModal"]);
  const data = ref();

  onMounted(() => {
    axios(djangoUrls.qr_codes).then((response) => {
      paymentsRef.value.showModal();
      data.value = response.data;
    });
  });

  const handleCloseEvent = () => {
    paymentsRef.value.closeModal();
    emit("closeModal");
  };
</script>

<template>
  <Teleport to="body">
    <ModalWindow ref="paymentsRef" @close-modal="handleCloseEvent">
      <template v-if="data" #header>
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="handleCloseEvent"
        ></button>
      </template>
      <template v-if="data" #body>
        <div class="row">
          <div class="col-12 d-flex">
            <template v-for="item in data" :key="item.id">
              <div class="mx-2">
                <h4 v-if="item.amount" class="text-center">
                  {{ item.amount }} Kč
                </h4>
                <h4 v-else class="text-center">Vlastní částka</h4>
                <img
                  :src="item.image"
                  class="img-thumbnail"
                  height="300"
                  width="300"
                  :alt="`${item.amount} Kč`"
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </ModalWindow>
  </Teleport>
</template>
