<script setup>
  import ModalWindow from "src/components/UI/ModalWindow.vue";
  import { onMounted, ref } from "vue";
  import axios from "axios";
  import { buildDetailUrl } from "src/utils";

  const props = defineProps({
    selectedActivity: {
      type: Number,
      required: true,
    },
  });

  const activityDetailRef = ref();

  const emit = defineEmits(["closeModal"]);

  const data = ref();

  onMounted(() => {
    const url = buildDetailUrl(djangoUrls.activity, {
      ":id": props.selectedActivity,
    });

    axios(url).then((response) => {
      activityDetailRef.value.showModal();
      data.value = response.data;
    });
  });

  const handleCloseEvent = () => {
    activityDetailRef.value.closeModal();
    emit("closeModal");
  };
</script>

<template>
  <ModalWindow ref="activityDetailRef" @close-modal="handleCloseEvent">
    <template v-if="data" #header>
      <h5 class="modal-title">{{ data.name }}</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="handleCloseEvent"
      ></button>
    </template>
    <template v-if="data" #body>
      <div class="px-2">
        <!-- eslint-disable vue/no-v-html -->
        <p v-html="data.full_text"></p>
        <!--eslint-enable-->
        <div v-if="data.photos">
          <template v-for="photo in data.photos" :key="photo.key">
            <a :href="photo.image" target="_blank">
              <img
                :src="photo.thumbnail.url"
                class="img-thumbnail"
                :height="200"
                :alt="photo.caption"
              />
            </a>
          </template>
        </div>
      </div>
    </template>
  </ModalWindow>
</template>
