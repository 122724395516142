<script setup>
  import { ref, onMounted, defineExpose, defineProps } from "vue";
  import Modal from "bootstrap/js/src/modal";

  defineProps({
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["closeModal"]);

  const modal = ref(); // template ref
  const modalInstance = ref();

  onMounted(() => {
    modalInstance.value = new Modal(modal.value);
    modal.value.addEventListener("hidden.bs.modal", () => {
      emit("closeModal");
    });
  });

  const closeModal = () => {
    modalInstance.value.hide();
  };

  const showModal = () => {
    modalInstance.value.show();
  };

  defineExpose({ showModal, closeModal });
</script>

<template>
  <div ref="modal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered" v-bind="$attrs">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
