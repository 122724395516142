<script setup>
  import { computed, reactive, ref } from "vue";
  import { email, helpers, required } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import { axiosConfig } from "src/utils";
  import axios from "axios";

  const initialState = {
    name: "",
    email: "",
    subject: "",
    body: "",
  };
  const state = reactive({ ...initialState });
  const rules = computed(() => {
    return {
      name: {
        required: helpers.withMessage("Toto pole je povinné", required),
      },
      email: {
        required: helpers.withMessage("Toto pole je povinné", required),
        email: helpers.withMessage("Zadejte validní e-mail", email),
      },
      subject: {
        required: helpers.withMessage("Toto pole je povinné", required),
      },
      body: {
        required: helpers.withMessage("Toto pole je povinné", required),
      },
    };
  });
  const $externalResults = ref({});
  const v$ = useVuelidate(rules, state, { $externalResults });

  const sending = ref(false);
  const sent = ref(false);
  const form = ref();

  const submit = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
      sending.value = true;

      axios
        .post(djangoUrls.contact_form, { ...state }, axiosConfig)
        .then((response) => {
          sending.value = false;
          if (response.data.errors) {
            $externalResults.value = response.data.errors;
          } else {
            sent.value = true;
          }
        })
        .catch(() => {
          alert("Nastala neočekávaná chyba");
        });
    }
  };
</script>

<template>
  <form ref="form" role="form" class="php-email-form" @submit.prevent="submit">
    <div v-if="!sending && !sent" class="row">
      <div class="col-md-6 form-group">
        <input
          id="name"
          v-model.lazy.trim="v$.name.$model"
          class="form-control"
          :class="{ 'form__row--error': v$.name.$error }"
          placeholder="Jméno"
        />
        <div class="form__errors">
          <p
            v-for="error of v$.name.$errors"
            :key="error.$uid"
            class="form__error"
          >
            {{ error.$message }}
          </p>
        </div>
      </div>
      <div class="col-md-6 form-group mt-3 mt-md-0">
        <input
          id="email"
          v-model.lazy.trim="v$.email.$model"
          type="email"
          class="form-control"
          :class="{ 'form__row--error': v$.email.$error }"
          placeholder="Email"
        />
        <div class="form__errors">
          <p
            v-for="error of v$.email.$errors"
            :key="error.$uid"
            class="form__error"
          >
            {{ error.$message }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!sending && !sent" class="form-group mt-3">
      <input
        id="subject"
        v-model.lazy.trim="v$.subject.$model"
        class="form-control"
        :class="{ 'form__row--error': v$.subject.$error }"
        placeholder="Předmět"
      />
      <div class="form__errors">
        <p
          v-for="error of v$.subject.$errors"
          :key="error.$uid"
          class="form__error"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
    <div v-if="!sending && !sent" class="form-group mt-3">
      <textarea
        v-model.lazy.trim="v$.body.$model"
        class="form-control"
        :class="{ 'form__row--error': v$.body.$error }"
        rows="5"
        placeholder="Zpráva"
      ></textarea>
      <div class="form__errors">
        <p
          v-for="error of v$.body.$errors"
          :key="error.$uid"
          class="form__error"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
    <div class="my-3">
      <div v-if="sending" class="loading">Odesílám</div>
      <div v-if="sent" class="sent-message">
        Vaše zpráva bylo odeslána. Děkujeme!
      </div>
    </div>
    <div v-if="!sending && !sent" class="text-center">
      <button type="submit">Odeslat</button>
    </div>
  </form>
</template>
