import { mountComponent } from "src/utils";
import PaymentModal from "src/components/PaymentModal.vue";

const appConfig = {
  components: { PaymentModal },
  data() {
    return {
      modalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
  },
};

mountComponent(".vuePayments", appConfig);
